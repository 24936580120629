// AdminConfig:adminConfigs
// AdminNotice:adminNotices
// Qna

import Vue from "vue";
import firebase from "firebase/compat/app"

export default {
};

export const database = {
	install(Vue) {
		Vue.prototype.$database = {
			currentTimestamp: function () {
				return firebase.firestore.Timestamp.now();
			},
			dateToTimestamp: function (date) {
				return firebase.firestore.Timestamp.fromDate(new Date(date));
			},
			timestampToDate: function (timestamp) {
				return timestamp.toDate();
			},
			onAdminConfigs: function (onSnapshot) {
				return firebase.firestore()
					.collection('adminConfigs')
					.onSnapshot(onSnapshot);
			},
			loadAdminConfigs: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('adminConfigs')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadAdminConfig: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('adminConfigs')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addAdminConfig: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('adminConfigs')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editAdminConfig: async function (id, model) {
				await firebase.firestore()
					.collection('adminConfigs')
					.doc(id)
					.update(model);
			},
			deleteAdminConfig: async function (id) {
				await firebase.firestore()
					.collection('adminConfigs')
					.doc(id)
					.delete();
			},
			setAdminConfig: async function (id, model) {
				await firebase.firestore()
					.collection('adminConfigs')
					.doc(id)
					.set(model);
			},

			onAdminNotices: function (onSnapshot) {
				return firebase.firestore()
					.collection('adminNotices')
					.onSnapshot(onSnapshot);
			},
			loadAdminNotices: async function (target) {
				let itemList = [];
				await firebase.firestore()
					.collection('adminNotices')
					.where('adminNoticeTarget', '==', target)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadAdminNoticesAll: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('adminNotices')
					.where('adminNoticeTarget', '!=', 'AdminNoticeTarget.partners')
					.orderBy('adminNoticeTarget', 'asc')
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadAdminNotice: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('adminNotices')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addAdminNotice: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('adminNotices')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editAdminNotice: async function (id, model) {
				await firebase.firestore()
					.collection('adminNotices')
					.doc(id)
					.update(model);
			},
			deleteAdminNotice: async function (id) {
				await firebase.firestore()
					.collection('adminNotices')
					.doc(id)
					.delete();
			},
			setAdminNotice: async function (id, model) {
				await firebase.firestore()
					.collection('adminNotices')
					.doc(id)
					.set(model);
			},
			onQnas: function (onSnapshot) {
				return firebase.firestore()
					.collection('qnas')
					.onSnapshot(onSnapshot);
			},
			loadQnas: async function (questionerType) {
				let itemList = [];
				await firebase.firestore()
					.collection('qnas')
					.where('questionerType', '==', questionerType)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadQnasAll: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('qnas')
					.where('isReplied', '!=', true)
					.orderBy('isReplied', 'asc')
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadQna: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('qnas')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addQna: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('qnas')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editQna: async function (id, model) {
				await firebase.firestore()
					.collection('qnas')
					.doc(id)
					.update(model);
			},
			deleteQna: async function (id) {
				await firebase.firestore()
					.collection('qnas')
					.doc(id)
					.delete();
			},
			setQna: async function (id, model) {
				await firebase.firestore()
					.collection('qnas')
					.doc(id)
					.set(model);
			},
			onHospitals: function (onSnapshot) {
				return firebase.firestore()
					.collection('hospitals')
					.onSnapshot(onSnapshot);
			},
			loadHospitals: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitals')
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							if (itemInfo.id !== 'initialInfo') {
								itemList.push(itemInfo);
							}
						}
					});
				return itemList;
			},
			loadHospitalsOfCode: async function (code) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitals')
					.where('signUpCode', '==', code)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalsOfSignUpCodePhone: async function (phone) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitals')
					.where('signUpCodePhone', '==', phone)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalsOrderBy: async function (orderBy) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitals')
					.orderBy(orderBy, 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							if (itemInfo.id !== 'initialInfo') {
								itemList.push(itemInfo);
							}
						}
					});
				return itemList;
			},
			loadHospital: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('hospitals')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addHospital: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('hospitals')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editHospital: async function (id, model) {
				await firebase.firestore()
					.collection('hospitals')
					.doc(id)
					.update(model);
			},
			deleteHospital: async function (id) {
				await firebase.firestore()
					.collection('hospitals')
					.doc(id)
					.delete();
			},
			setHospital: async function (id, model) {
				await firebase.firestore()
					.collection('hospitals')
					.doc(id)
					.set(model);
			},
			onHospitalWorks: function (
				hospitalId,
				startDate,
				endDate,
				patientNameFilter,
				clinicStateFilter,
				isVideoClinicFilter,
				timestampField,
				orderBy,
				onSnapshot) {
				let ref = firebase.firestore().collection('hospitalWorks')
					.where('clinicState', 'in', clinicStateFilter);
				if (startDate !== null) {
					ref = ref.where(timestampField, '>=', startDate);
				}
				if (endDate !== null) {
					ref = ref.where(timestampField, '<=', endDate);
				}
				if (patientNameFilter.length > 0) {
					ref = ref.where('patientName', '==', patientNameFilter);
				}
				if (isVideoClinicFilter !== null) {
					ref = ref.where('isVideoClinic', '==', isVideoClinicFilter);
				}
				if (hospitalId !== null) {
					ref = ref.where('hospitalId', '==', hospitalId);
				}
				return ref.orderBy(timestampField, orderBy)
					.onSnapshot(onSnapshot);
			},
			loadHospitalWorks: async function (orderBy) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('hospitalWorks')
					.orderBy(orderBy, 'desc');
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalWorksSettled: async function (isSettled) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('hospitalWorks')
					.orderBy('createAt', 'desc');
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;

							if (isSettled !== null) {
								if (isSettled === true) {
									if (itemInfo.isSettled === true) {
										itemList.push(itemInfo);
									}
								}
								else {
									if (itemInfo.isSettled !== true) {
										itemList.push(itemInfo);
									}
								}
							}
							else {
								itemList.push(itemInfo);
							}
						}
					});
				return itemList;
			},
			loadHospitalWorks2: async function (
				purchaseStartDate,
				purchaseEndDate,
				settledStartDate,
				settledEndDate,
				hospitalNameFilter,
				isSettledFilter,
				orderBy) {
				let itemList = [];
				let ref = firebase.firestore().collection('hospitalWorks')
					.where('clinicState', '==', 'ClinicState.done');
				if ((purchaseStartDate !== null) && (purchaseEndDate !== null)) {
					ref = ref.where('payAt', '>=', purchaseStartDate)
						.where('payAt', '<=', purchaseEndDate);
				}
				if ((settledStartDate !== null) && (settledEndDate !== null)) {
					ref = ref.where('settledAt', '>=', settledStartDate)
						.where('settledAt', '<=', settledEndDate);
				}
				if (hospitalNameFilter.length > 0) {
					ref = ref.where('hospitalName', '==', hospitalNameFilter);
				}
				await ref.orderBy(orderBy, 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;

							if (isSettledFilter !== null) {
								if (isSettledFilter === true) {
									if (itemInfo.isSettled === true) {
										itemList.push(itemInfo);
									}
								}
								else {
									if (itemInfo.isSettled !== true) {
										itemList.push(itemInfo);
									}
								}
							}
							else {
								itemList.push(itemInfo);
							}
						}
					});
				return itemList;
			},
			loadHospitalWorksByPatientId: async function (patientId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('hospitalWorks')
					.where('patientId', '==', patientId)
					.where('clinicState', '!=', 'ClinicState.cancel');
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalWorksByHospitalId: async function (hospitalId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('hospitalWorks')
					.where('hospitalId', '==', hospitalId)
					.where('clinicState', '!=', 'ClinicState.cancel');
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalWork: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('hospitalWorks')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addHospitalWork: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('hospitalWorks')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editHospitalWork: async function (id, model) {
				await firebase.firestore()
					.collection('hospitalWorks')
					.doc(id)
					.update(model);
			},
			deleteHospitalWork: async function (id) {
				await firebase.firestore()
					.collection('hospitalWorks')
					.doc(id)
					.delete();
			},
			setHospitalWork: async function (id, model) {
				await firebase.firestore()
					.collection('hospitalWorks')
					.doc(id)
					.set(model);
			},
			onPharmacys: function (onSnapshot) {
				return firebase.firestore()
					.collection('pharmacys')
					.onSnapshot(onSnapshot);
			},
			loadPharmacys: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('pharmacys')
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPharmacy: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('pharmacys')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPharmacy: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('pharmacys')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editPharmacy: async function (id, model) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(id)
					.update(model);
			},
			deletePharmacy: async function (id) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(id)
					.delete();
			},
			setPharmacy: async function (id, model) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(id)
					.set(model);
			},
			loadPharmacyWorks: async function (orderBy) {
				let itemList = [];
				await firebase.firestore()
					.collection('pharmacyWorks')
					.orderBy(orderBy, 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPharmacyWorksInDay: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('pharmacyWorks')
					.where('deliveryType', '==', 'DeliveryType.inDay')
					.orderBy('payAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPharmacyWorks2: async function (
				purchaseStartDate,
				purchaseEndDate,
				settledStartDate,
				settledEndDate,
				pharmacyNameFilter,
				isSettledFilter,
				orderBy) {
				// console.log('loadPharmacyWorks2',
				// 	purchaseStartDate,
				// 	purchaseEndDate,
				// 	settledStartDate,
				// 	settledEndDate,
				// 	pharmacyNameFilter,
				// 	isSettledFilter,
				// 	orderBy);
				let itemList = [];
				let ref = firebase.firestore().collection('pharmacyWorks')
					.where('deliveryState', '==', 'DeliveryState.deliveryDone');
				if ((purchaseStartDate !== null) && (purchaseEndDate !== null)) {
					ref = ref.where('payAt', '>=', purchaseStartDate)
						.where('payAt', '<=', purchaseEndDate);
				}
				if ((settledStartDate !== null) && (settledEndDate !== null)) {
					ref = ref.where('settledAt', '>=', settledStartDate)
						.where('settledAt', '<=', settledEndDate);
				}
				if (pharmacyNameFilter.length > 0) {
					ref = ref.where('pharmacyName', '==', pharmacyNameFilter);
				}
				await ref.orderBy(orderBy, 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							// console.log('itemInfo', itemInfo);
							itemInfo.id = querySnapshot.docs[i].id;

							if (isSettledFilter !== null) {
								if (isSettledFilter === true) {
									if (itemInfo.isSettled === true) {
										itemList.push(itemInfo);
									}
								}
								else {
									if (itemInfo.isSettled !== true) {
										itemList.push(itemInfo);
									}
								}
							}
							else {
								itemList.push(itemInfo);
							}
						}
					});
				return itemList;
			},
			loadPharmacyWorksByPatientId: async function (patientId) {
				let itemList = [];
				await firebase.firestore()
					.collection('pharmacyWorks')
					.where('patientId', '==', userId)
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPharmacyWorksByPharmacyId: async function (pharmacyId) {
				let itemList = [];
				await firebase.firestore()
					.collection('pharmacyWorks')
					.where('pharmacyId', '==', pharmacyId)
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPharmacyWork: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('pharmacyWorks')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPharmacyWork: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('pharmacyWorks')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editPharmacyWork: async function (id, model) {
				await firebase.firestore()
					.collection('pharmacyWorks')
					.doc(id)
					.update(model);
			},
			deletePharmacyWork: async function (id) {
				await firebase.firestore()
					.collection('pharmacyWorks')
					.doc(id)
					.delete();
			},
			setPharmacyWork: async function (id, model) {
				await firebase.firestore()
					.collection('pharmacyWorks')
					.doc(id)
					.set(model);
			},
			onUsers: function (onSnapshot) {
				return firebase.firestore()
					.collection('users')
					.onSnapshot(onSnapshot);
			},
			loadUsers: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('users')
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadUsersForHospital: async function (usersMap, hospitalId, clinicStartDate, clinicEndDate, reservationStartDate, reservationEndDate) {
				let users = {};
				let ref = firebase.firestore()
					.collection('hospitalWorks')
					.where('hospitalId', '==', hospitalId);

				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let hospitalWork = querySnapshot.docs[i].data();

							// a. 진료일 필터가 설정된 경우 진료완료 상태이면서 진료일 범위에 포함되어있으면 사용자 목록에 표시돼야 함
							// b. 예약일 필터가 설정된 경우 진료예약 상태이면서 예약일 범위에 포함되어있으면 사용자 목록에 표시돼야 함
							// a만 설정되어있으면 a 조건이 참이어야 함
							// b만 설정되어있으면 b 조건이 참이어야 함
							// 둘다 설정되어있으면 a, b 조건 중 하나라도 참이면 사용자 목록에 표시돼야 함

							let inClinicDate = false;
							if ((clinicStartDate !== null) && (clinicEndDate !== null)) { // 진료일 필터가 설정된 경우
								if (hospitalWork.clinicState === 'ClinicState.done') { // 진료완료 상태인 경우
									if ((hospitalWork.clinicEndAt !== undefined) && (hospitalWork.clinicEndAt !== null)) {
										if ((hospitalWork.clinicEndAt.toDate() >= clinicStartDate) && (hospitalWork.clinicEndAt.toDate() <= clinicEndDate)) {
											inClinicDate = true;
										}
									}
								}
							}

							let inReservationDate = false;
							if ((reservationStartDate !== null) && (reservationEndDate !== null)) { // 예약일 필터가 설정된 경우
								if ((hospitalWork.clinicState === 'ClinicState.waiting') || (hospitalWork.clinicState === 'ClinicState.accepted')) { // 진료 전 상태인 경우
									if ((hospitalWork.reservationDateTime !== undefined) && (hospitalWork.reservationDateTime !== null)) {
										if ((hospitalWork.reservationDateTime.toDate() >= reservationStartDate) && (hospitalWork.reservationDateTime.toDate() <= reservationEndDate)) {
											inReservationDate = true;
										}
									}
								}
							}

							let addUser = false;
							if ((clinicStartDate !== null) && (clinicEndDate !== null) && (reservationStartDate !== null) && (reservationEndDate !== null)) {
								// 진료일 필터, 예약일 필터 모두 설정된 경우
								if (inClinicDate || inReservationDate) {
									addUser = true;
								}
							}
							else if ((clinicStartDate !== null) && (clinicEndDate !== null)) {
								// 진료일 필터만 설정된 경우
								if (inClinicDate) {
									addUser = true;
								}
							}
							else if ((reservationStartDate !== null) && (reservationEndDate !== null)) {
								// 예약일 필터만 설정된 경우
								if (inReservationDate) {
									addUser = true;
								}
							}
							else {
								addUser = true;
							}

							if (addUser) {
								if ((users[hospitalWork.patientId] === null) || (users[hospitalWork.patientId] === undefined)) {
									if ((usersMap[hospitalWork.patientId] !== null) && (usersMap[hospitalWork.patientId] !== undefined)) {
										let user = usersMap[hospitalWork.patientId];
										let userData = Object.assign(usersMap[hospitalWork.patientId], {});
										users[hospitalWork.patientId] = Object.assign(user, {
											user: userData,
											clinicCount: 0,
											reservationCount: 0,
											clinicDate: null,
											reservationDate: null,
											paymentAmount: 0,
											receivableAmount: 0
										});
									}
								}

								let user = users[hospitalWork.patientId];
								if ((user !== undefined) && (user !== null)) {
									if (hospitalWork.clinicState === 'ClinicState.done') {
										user.clinicCount += 1;
										if ((hospitalWork.clinicEndAt !== undefined) && (hospitalWork.clinicEndAt !== null)) {
											if ((user.clinicDate === null) || (user.clinicDate.toDate() < hospitalWork.clinicEndAt.toDate())) {
												user.clinicDate = hospitalWork.clinicEndAt;
											}
										}
										if ((hospitalWork.paymentAmount !== undefined) && (hospitalWork.paymentAmount !== null)) {
											if (hospitalWork.refunded === true) {
												// 환불된 경우
												// 미수금
												user.receivableAmount += hospitalWork.paymentAmount;
											}
											else if ((hospitalWork.payAt !== undefined) && (hospitalWork.payAt !== null)) {
												// 결제 이력이 있는 경우
												if (hospitalWork.payFail !== true) { // 결제 실패가 아니면
													user.paymentAmount += hospitalWork.paymentAmount;
												}
												else { // 결제 실패
													// 미수금
													user.receivableAmount += hospitalWork.paymentAmount;
												}
											}
											else {
												// 결제 이력이 없는 경우
												// 미수금
												user.receivableAmount += hospitalWork.paymentAmount;
											}
										}
									}
									else if ((hospitalWork.clinicState === 'ClinicState.waiting') || (hospitalWork.clinicState === 'ClinicState.accepted')) {
										user.reservationCount += 1;
										if ((user.reservationDate === null)
											|| (user.reservationDate.toDate() < hospitalWork.reservationDateTime.toDate())) {
											user.reservationDate = hospitalWork.reservationDateTime;
										}
									}

									users[hospitalWork.patientId] = user;
								}
							}
						}
					});
				return users;
			},
			loadUsersOfFavoriteHospital: async function (hospitalId) {
				let itemList = [];
				await firebase.firestore()
					.collection('users')
					.where('favoriteHospitals', 'array-contains', hospitalId)
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadUser: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('users')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addUser: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('users')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editUser: async function (id, model) {
				await firebase.firestore()
					.collection('users')
					.doc(id)
					.update(model);
			},
			deleteUser: async function (id) {
				await firebase.firestore()
					.collection('users')
					.doc(id)
					.delete();
			},
			setUser: async function (id, model) {
				await firebase.firestore()
					.collection('users')
					.doc(id)
					.set(model);
			},
			loadHospitalMembers: async function (hospitalId) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalWorkers')
					.where('hospitalId', '==', hospitalId)
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			onCoupons: function (onSnapshot) {
				return firebase.firestore()
					.collection('coupons')
					.onSnapshot(onSnapshot);
			},
			loadCoupons: async function (createAtStartDate, createAtEndDate) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('coupons');
				if (createAtStartDate !== null) {
					ref = ref.where('createAt', '>=', firebase.firestore.Timestamp.fromDate(createAtStartDate));
				}
				if (createAtEndDate !== null) {
					ref = ref.where('createAt', '<=', firebase.firestore.Timestamp.fromDate(createAtEndDate));
				}
				await ref.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadCoupon: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('coupons')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addCoupon: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('coupons')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editCoupon: async function (id, model) {
				await firebase.firestore()
					.collection('coupons')
					.doc(id)
					.update(model);
			},
			deleteCoupon: async function (id) {
				await firebase.firestore()
					.collection('coupons')
					.doc(id)
					.delete();
			},
			setCoupon: async function (id, model) {
				await firebase.firestore()
					.collection('coupons')
					.doc(id)
					.set(model);
			},
			loadQuickInfoByPharmacyWorkId: async function (pharmacyWorkId) {
				let itemList = [];
				await firebase.firestore()
					.collection('quickInfos')
					.where('pharmacyWorkId', '==', pharmacyWorkId)
					//.orderBy('createAt', 'asc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPaymentsByHospitalWork: async function (hospitalWorkId) {
				let itemList = [];
				await firebase.firestore()
					.collection('payments')
					.where('hospitalWorkId', '==', hospitalWorkId)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPaymentsByPharmacyWork: async function (pharmacyWorkId) {
				let itemList = [];
				await firebase.firestore()
					.collection('payments')
					.where('hospitalWorkId', '==', pharmacyWorkId)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPaymentsByCertificateRequest: async function (certificateRequestId) {
				let itemList = [];
				await firebase.firestore()
					.collection('payments')
					.where('certificateRequestId', '==', certificateRequestId)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			onPopups: function (onSnapshot) {
				return firebase.firestore()
					.collection('popups')
					.onSnapshot(onSnapshot);
			},
			loadPopups: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('popups')
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPopup: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('popups')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPopup: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('popups')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editPopup: async function (id, model) {
				await firebase.firestore()
					.collection('popups')
					.doc(id)
					.update(model);
			},
			deletePopup: async function (id) {
				await firebase.firestore()
					.collection('popups')
					.doc(id)
					.delete();
			},
			setPopup: async function (id, model) {
				await firebase.firestore()
					.collection('popups')
					.doc(id)
					.set(model);
			},
			loadHospitalSubscriptions: async function (hospitalId) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalSubscriptions')
					.where('hospitalId', '==', hospitalId)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadStatistics: async function (collection1, collection2, startTimestamp, endTimestamp) {
				let itemList = [];
				await firebase.firestore()
					.collection(collection1)
					.doc(collection1)
					.collection(collection2)
					.where('createAt', '>=', startTimestamp)
					.where('createAt', '<=', endTimestamp)
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			onFamilys: function (onSnapshot) {
				return firebase.firestore()
					.collection('familys')
					.onSnapshot(onSnapshot);
			},
			loadFamilys: async function (appUserId) {
				let itemList = [];
				await firebase.firestore()
					.collection('familys')
					.where('appUserId', '==', appUserId)
					.orderBy('createAt', 'asc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadFamily: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('familys')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addFamily: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('familys')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editFamily: async function (id, model) {
				await firebase.firestore()
					.collection('familys')
					.doc(id)
					.update(model);
			},
			deleteFamily: async function (id) {
				await firebase.firestore()
					.collection('familys')
					.doc(id)
					.delete();
			},
			setFamily: async function (id, model) {
				await firebase.firestore()
					.collection('familys')
					.doc(id)
					.set(model);
			},
			onAdminNotifications: function (onSnapshot) {
				return firebase.firestore()
					.collection('adminNotifications')
					.onSnapshot(onSnapshot);
			},
			loadAdminNotifications: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('adminNotifications')
					.orderBy('reservedAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadAdminNotification: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('adminNotifications')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addAdminNotification: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('adminNotifications')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editAdminNotification: async function (id, model) {
				await firebase.firestore()
					.collection('adminNotifications')
					.doc(id)
					.update(model);
			},
			deleteAdminNotification: async function (id) {
				await firebase.firestore()
					.collection('adminNotifications')
					.doc(id)
					.delete();
			},
			setAdminNotification: async function (id, model) {
				await firebase.firestore()
					.collection('adminNotifications')
					.doc(id)
					.set(model);
			},
			loadHospitalWorkersByHospitalId: async function (hospitalId) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalWorkers')
					.where('hospitalId', '==', hospitalId)
					.orderBy('createAt', 'asc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalWorkersByHospitalIdOrder: async function (hospitalId) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalWorkers')
					.where('hospitalId', '==', hospitalId)
					.orderBy('order', 'asc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalWorker: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('hospitalWorkers')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			loadPharmacists: async function (pharmacyId) {
				let itemList = [];
				await firebase.firestore()
					.collection('pharmacys')
					.doc(pharmacyId)
					.collection('pharmacists')
					.orderBy('createAt', 'asc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			editHospitalWorker: async function (id, model) {
				await firebase.firestore()
					.collection('hospitalWorkers')
					.doc(id)
					.update(model);
			},
			editHospitalWorkersOrder: async function (items) {
				await firebase.firestore().runTransaction(async (transaction) => {
					for(let i=0;i<items.length;i++) {
						let ref = firebase.firestore().collection('hospitalWorkers').doc(items[i].id);
						transaction.update(ref, {
							order: items[i].order
						});
					}
				});
			},
			deleteHospitalWorker: async function (id) {
				await firebase.firestore()
					.collection('hospitalWorkers')
					.doc(id)
					.delete();
			},
			editPharmacist: async function (pharmacyId, id, model) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(pharmacyId)
					.collection('pharmacists')
					.doc(id)
					.update(model);
			},
			deletePharmacist: async function (pharmacyId, id) {
				await firebase.firestore()
					.collection('pharmacys')
					.doc(pharmacyId)
					.collection('pharmacists')
					.doc(id)
					.delete();
			},
			onMembershipUsages: function (onSnapshot) {
				return firebase.firestore()
					.collection('membershipUsages')
					.onSnapshot(onSnapshot);
			},
			addMembershipUsageForPushCount: async function (hospital, pushCount) {
				let thisMonth = new Date();
				thisMonth.setDate(1);
				thisMonth.setHours(0, 0, 0, 0);
				let nextMonth = new Date(thisMonth);
				nextMonth.setMonth(nextMonth.getMonth() + 1);
				let thisMonthTimestamp = firebase.firestore.Timestamp.fromDate(thisMonth);
				//let nextMonthTimestamp = firebase.firestore.Timestamp.fromDate(nextMonth);
				let addModel = {};
				addModel.hospitalId = hospital.id;
				addModel.month = thisMonthTimestamp;
				addModel.pushCount = pushCount;
				addModel.normalClinicCount = 0;
				addModel.normalClinicSeconds = 0;
				addModel.audioClinicCount = 0;
				addModel.audioClinicSeconds = 0;
				addModel.videoClinicCount = 0;
				addModel.videoClinicSeconds = 0;
				await firebase.firestore()
					.collection('membershipUsages')
					.add(addModel);
			},
			loadMembershipUsages: async function () {
				let thisMonth = new Date();
				thisMonth.setDate(1);
				thisMonth.setHours(0, 0, 0, 0);
				let nextMonth = new Date(thisMonth);
				nextMonth.setMonth(thisMonth.getMonth() + 1);
				let itemList = [];
				await firebase.firestore()
					.collection('membershipUsages')
					.where('month', '>=', firebase.firestore.Timestamp.fromDate(thisMonth))
					.where('month', '<', firebase.firestore.Timestamp.fromDate(nextMonth))
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadMembershipUsage: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('membershipUsages')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addMembershipUsage: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('membershipUsages')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			deleteMembershipUsage: async function (id) {
				await firebase.firestore()
					.collection('membershipUsages')
					.doc(id)
					.delete();
			},
			setMembershipUsage: async function (id, model) {
				await firebase.firestore()
					.collection('membershipUsages')
					.doc(id)
					.set(model);
			},
			loadHospitalWorksThisMonth: async function () {
				let thisMonth = new Date();
				thisMonth.setDate(1);
				thisMonth.setHours(0, 0, 0, 0);
				let nextMonth = new Date(thisMonth);
				nextMonth.setMonth(thisMonth.getMonth() + 1);
				let itemList = [];
				let ref = firebase.firestore()
					.collection('hospitalWorks')
					.where('clinicEndAt', '>=', firebase.firestore.Timestamp.fromDate(thisMonth))
					.where('clinicEndAt', '<', firebase.firestore.Timestamp.fromDate(nextMonth));
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			editMembershipUsage: async function (hospitalId, data) {
				let thisMonth = new Date();
				thisMonth.setDate(1);
				thisMonth.setHours(0, 0, 0, 0);
				let nextMonth = new Date(thisMonth);
				nextMonth.setMonth(nextMonth.getMonth() + 1);
				let thisMonthTimestamp = firebase.firestore.Timestamp.fromDate(thisMonth);
				let nextMonthTimestamp = firebase.firestore.Timestamp.fromDate(nextMonth);
				let model = Object.assign({
					hospitalId: hospitalId,
					month: thisMonth,
					pushCount: 0,
				}, data);
				await firebase.firestore()
					.collection('membershipUsages')
					.where('hospitalId', '==', hospitalId)
					.where('month', '>=', thisMonthTimestamp)
					.where('month', '<', nextMonthTimestamp)
					.get()
					.then(async (querySnapshot) => {
						if (querySnapshot.docs.length > 0) {
							let ref = firebase.firestore()
								.collection('membershipUsages').doc(querySnapshot.docs[0].id);
							await firebase.firestore().runTransaction(async (transaction) => {
								await transaction.get(ref).then(async (documentSnapshot) => {
									if (documentSnapshot.exists) {
										transaction.update(ref, model);
									}
								});
							});
						}
						else {
							await firebase.firestore()
								.collection('membershipUsages')
								.add(model);
						}
					});
			},
			findHospitalCode: async function (hospitalCode) {
				let result = false;
				await firebase.firestore()
					.collection('hospitals')
					.where('hospitalCode', '==', hospitalCode)
					.get()
					.then((querySnapshot) => {
						if (querySnapshot.docs.length > 0) {
							result = true;
						}
					});
				return result;
			},
			addNotification: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('notifications')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			loadHospitalWorkersByType: async function (hospitalId, type) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalWorkers')
					.where('hospitalId', '==', hospitalId)
					.where('hospitalWorkerType', '==', type)
					.orderBy('createAt', 'asc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalWorkersByTypeIn: async function (hospitalId, types) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalWorkers')
					.where('hospitalId', '==', hospitalId)
					.where('hospitalWorkerType', 'in', types)
					.orderBy('createAt', 'asc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			onHospitalNotices: function (onSnapshot) {
				return firebase.firestore()
					.collection('hospitalNotices')
					.onSnapshot(onSnapshot);
			},
			loadHospitalNotices: async function (hospitalId) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalNotices')
					.where('hospitalId', '==', hospitalId)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalNotice: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('hospitalNotices')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addHospitalNotice: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('hospitalNotices')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editHospitalNotice: async function (id, model) {
				await firebase.firestore()
					.collection('hospitalNotices')
					.doc(id)
					.update(model);
			},
			deleteHospitalNotice: async function (id) {
				await firebase.firestore()
					.collection('hospitalNotices')
					.doc(id)
					.delete();
			},
			setHospitalNotice: async function (id, model) {
				await firebase.firestore()
					.collection('hospitalNotices')
					.doc(id)
					.set(model);
			},
			onNonBenefits: function (onSnapshot) {
				return firebase.firestore()
					.collection('nonBenefits')
					.onSnapshot(onSnapshot);
			},
			loadNonBenefits: async function (hospitalId) {
				let itemList = [];
				await firebase.firestore()
					.collection('nonBenefits')
					.where('hospitalId', '==', hospitalId)
					.orderBy('orderPriority', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadNonBenefit: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('nonBenefits')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addNonBenefit: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('nonBenefits')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editNonBenefit: async function (id, model) {
				await firebase.firestore()
					.collection('nonBenefits')
					.doc(id)
					.update(model);
			},
			deleteNonBenefit: async function (id) {
				await firebase.firestore()
					.collection('nonBenefits')
					.doc(id)
					.delete();
			},
			setNonBenefit: async function (id, model) {
				await firebase.firestore()
					.collection('nonBenefits')
					.doc(id)
					.set(model);
			},
			loadReservedPushNotifications: async function (reservedAtStart, reservedAtEnd, sendMethodFilter) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('reservedPushNotifications')
					.where('senderType', '==', 'hospital');
				if (reservedAtStart !== null) {
					ref = ref.where('reservedAt', '>=', reservedAtStart);
				}
				if (reservedAtEnd !== null) {
					ref = ref.where('reservedAt', '<=', reservedAtEnd);
				}
				if (sendMethodFilter !== null) {
					for (let i = 0; i < sendMethodFilter.length; i++) {
						ref = ref.where('sendMethods', 'array-contains', sendMethodFilter[i]);
					}
				}
				await ref.orderBy('reservedAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadReservedPushNotificationsByHospitalWorkId: async function (hospitalWorkId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('reservedPushNotifications')
					.where('hospitalWorkId', '==', hospitalWorkId)
					.where('sended', '==', false)
					.orderBy('reservedAt', 'asc');
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadReservedPushNotificationsStatistics: async function (startTimestamp, endTimestamp) {
				let itemList = [];
				await firebase.firestore()
					.collection('reservedPushNotifications')
					.where('senderType', '==', 'hospital')
					.where('sended', '==', true)
					.where('sendedAt', '>=', startTimestamp)
					.where('sendedAt', '<=', endTimestamp)
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalMileagePayments: async function (hospitalId) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalPayments')
					.where('hospitalId', '==', hospitalId)
					.where('mileagePayment', '==', true)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalPaymentsByHospitalId: async function (type, hospitalId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('hospitalPayments');
				if (type !== null) {
					ref = ref.where('type', '==', type);
				}
				await ref.where('hospitalId', '==', hospitalId)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalPaymentsBySubscriptionId: async function (subscriptionId) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalPayments')
					.where('subscriptionId', '==', subscriptionId)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalPayment: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('hospitalPayments')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addHospitalPayment: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('hospitalPayments')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
				return addModel.id;
			},
			editHospitalPayment: async function (id, model) {
				await firebase.firestore()
					.collection('hospitalPayments')
					.doc(id)
					.update(model);
			},
			deleteHospitalPayment: async function (id) {
				await firebase.firestore()
					.collection('hospitalPayments')
					.doc(id)
					.delete();
			},
			setHospitalPayment: async function (id, model) {
				await firebase.firestore()
					.collection('hospitalPayments')
					.doc(id)
					.set(model);
			},
			loadMembershipUsagesThisMonth: async function (hospitalId) {
				let thisMonth = new Date();
				thisMonth.setDate(1);
				thisMonth.setHours(0, 0, 0, 0);
				let nextMonth = new Date(thisMonth);
				nextMonth.setMonth(nextMonth.getMonth() + 1);
				let itemList = [];
				await firebase.firestore()
					.collection('membershipUsages')
					.where('hospitalId', '==', hospitalId)
					.where('month', '>=', thisMonth)
					.where('month', '<', nextMonth)
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalSubscriptionsByHospitalId: async function (hospitalId) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalSubscriptions')
					.where('hospitalId', '==', hospitalId)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			onCouponUsages: function (onSnapshot) {
				return firebase.firestore()
					.collection('couponUsages')
					.onSnapshot(onSnapshot);
			},
			loadCouponUsages: async function (limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('couponUsages');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadCouponUsagesByCouponId: async function (couponId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('couponUsages')
					.where('couponId', '==', couponId);
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadCouponUsage: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('couponUsages')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addCouponUsage: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('couponUsages')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editCouponUsage: async function (id, model) {
				await firebase.firestore()
					.collection('couponUsages')
					.doc(id)
					.update(model);
			},
			deleteCouponUsage: async function (id) {
				await firebase.firestore()
					.collection('couponUsages')
					.doc(id)
					.delete();
			},
			setCouponUsage: async function (id, model) {
				await firebase.firestore()
					.collection('couponUsages')
					.doc(id)
					.set(model);
			},
			loadHospitalWorksByPatientIdAndResidentNumber: async function (hospitalId, patientId, hospitalWorkId, patientResidentNumber) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalWorks')
					.where('hospitalId', '==', hospitalId)
					.where('patientId', '==', patientId)
					.where('patientResidentNumber', '==', patientResidentNumber)
					.where('clinicState', '==', 'ClinicState.done')
					.orderBy('clinicEndAt', 'desc')
					.limit(3)
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							if ((hospitalWorkId === null) || (itemInfo.id !== hospitalWorkId)) {
								itemList.push(itemInfo);
							}
						}
					});
				return itemList;
			},
			onPatientInfos: function (hospitalId, onSnapshot) {
				return firebase.firestore()
					.collection('hospitals')
					.doc(hospitalId)
					.collection('patientInfos')
					.onSnapshot(onSnapshot);
			},
			loadPatientInfos: async function (hospitalId, patientId, familyPatient, limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('hospitals')
					.doc(hospitalId)
					.collection('patientInfos')
					.where('familyPatient', '==', familyPatient)
					.where('patientId', '==', patientId);
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPatientInfo: async function (hospitalId, id) {
				let item = null;
				await firebase.firestore()
					.collection('hospitals')
					.doc(hospitalId)
					.collection('patientInfos')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPatientInfo: async function (hospitalId, model) {
				let newDocRef = firebase.firestore()
					.collection('hospitals')
					.doc(hospitalId)
					.collection('patientInfos')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editPatientInfo: async function (hospitalId, id, model) {
				await firebase.firestore()
					.collection('hospitals')
					.doc(hospitalId)
					.collection('patientInfos')
					.doc(id)
					.update(model);
			},
			deletePatientInfo: async function (hospitalId, id) {
				await firebase.firestore()
					.collection('hospitals')
					.doc(hospitalId)
					.collection('patientInfos')
					.doc(id)
					.delete();
			},
			setPatientInfo: async function (hospitalId, id, model) {
				await firebase.firestore()
					.collection('hospitals')
					.doc(hospitalId)
					.collection('patientInfos')
					.doc(id)
					.set(model);
			},
			onPharmacyWorks: function (pharmacyId,
				startDate,
				endDate,
				patientNameFilter,
				hospitalNameFilter,
				deliveryStateFilter,
				onSnapshot) {
				let ref = firebase.firestore().collection('pharmacyWorks')
					.where('deliveryState', 'in', deliveryStateFilter);
				if (pharmacyId !== null) {
					ref = ref.where('pharmacyId', '==', pharmacyId);
				}
				if ((startDate !== null) && (endDate !== null)) {
					ref = ref.where('createAt', '>=', startDate)
						.where('createAt', '<=', endDate);
				}
				if (patientNameFilter.length > 0) {
					ref = ref.where('patientName', '==', patientNameFilter);
				}
				if (hospitalNameFilter.length > 0) {
					ref = ref.where('hospitalName', '==', hospitalNameFilter);
				}
				return ref.orderBy('createAt', 'desc').onSnapshot(onSnapshot);
			},
			onPharmacyWork: function (id, onSnapshot) {
				return firebase.firestore()
					.collection('pharmacyWorks')
					.doc(id).onSnapshot(onSnapshot);
			},
			loadHospitalWorksByDoctorId: async function (
				hospitalId,
				doctorId) {
				let itemList = [];
				let ref = firebase.firestore().collection('hospitalWorks')
					.where('hospitalId', '==', hospitalId)
					.where('doctorId', '==', doctorId);
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			onDeletedHospitalWorkers: function (onSnapshot) {
				return firebase.firestore()
					.collection('deletedHospitalWorkers')
					.onSnapshot(onSnapshot);
			},
			loadDeletedHospitalWorkers: async function (limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('deletedHospitalWorkers');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadDeletedHospitalWorker: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('deletedHospitalWorkers')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addDeletedHospitalWorker: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('deletedHospitalWorkers')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editDeletedHospitalWorker: async function (id, model) {
				await firebase.firestore()
					.collection('deletedHospitalWorkers')
					.doc(id)
					.update(model);
			},
			deleteDeletedHospitalWorker: async function (id) {
				await firebase.firestore()
					.collection('deletedHospitalWorkers')
					.doc(id)
					.delete();
			},
			setDeletedHospitalWorker: async function (id, model) {
				await firebase.firestore()
					.collection('deletedHospitalWorkers')
					.doc(id)
					.set(model);
			},

			onDeletedHospitals: function (onSnapshot) {
				return firebase.firestore()
					.collection('deletedHospitals')
					.onSnapshot(onSnapshot);
			},
			loadDeletedHospitals: async function (limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('deletedHospitals');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadDeletedHospital: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('deletedHospitals')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addDeletedHospital: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('deletedHospitals')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editDeletedHospital: async function (id, model) {
				await firebase.firestore()
					.collection('deletedHospitals')
					.doc(id)
					.update(model);
			},
			deleteDeletedHospital: async function (id) {
				await firebase.firestore()
					.collection('deletedHospitals')
					.doc(id)
					.delete();
			},
			setDeletedHospital: async function (id, model) {
				await firebase.firestore()
					.collection('deletedHospitals')
					.doc(id)
					.set(model);
			},

			onDeletedPharmacys: function (onSnapshot) {
				return firebase.firestore()
					.collection('deletedPharmacys')
					.onSnapshot(onSnapshot);
			},
			loadDeletedPharmacys: async function (limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('deletedPharmacys');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadDeletedPharmacy: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('deletedPharmacys')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addDeletedPharmacy: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('deletedPharmacys')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editDeletedPharmacy: async function (id, model) {
				await firebase.firestore()
					.collection('deletedPharmacys')
					.doc(id)
					.update(model);
			},
			deleteDeletedPharmacy: async function (id) {
				await firebase.firestore()
					.collection('deletedPharmacys')
					.doc(id)
					.delete();
			},
			setDeletedPharmacy: async function (id, model) {
				await firebase.firestore()
					.collection('deletedPharmacys')
					.doc(id)
					.set(model);
			},
			addOnlyHospitalWorker: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('hospitalWorkers')
					.doc(model.id);
				let addModel = model;
				await newDocRef.set(addModel);
			},
			// addNonBenefit: async function (model) {
			// 	let newDocRef = firebase.firestore()
			// 		.collection('nonBenefits')
			// 		.doc();
			// 	let addModel = model;
			// 	addModel.id = newDocRef.id;
			// 	await newDocRef.set(addModel);
			// },
			// editNonBenefit: async function (id, model) {
			// 	await firebase.firestore()
			// 		.collection('nonBenefits')
			// 		.doc(id)
			// 		.update(model);
			// },
			// deleteNonBenefit: async function (id) {
			// 	await firebase.firestore()
			// 		.collection('nonBenefits')
			// 		.doc(id)
			// 		.delete();
			// },
			onTempHolidays: function (onSnapshot) {
				return firebase.firestore()
					.collection('tempHolidays')
					.onSnapshot(onSnapshot);
			},
			loadTempHolidaysHospital: async function (hospitalId, limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('tempHolidays')
					.where('hospitalId', '==', hospitalId)
					.orderBy('date', 'asc');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadTempHolidaysHospitalOfHospitalWorker: async function (hospitalWorkerId, limit) {
				console.log('loadTempHolidaysHospitalOfHospitalWorker', hospitalWorkerId);
				let itemList = [];
				let ref = firebase.firestore()
					.collection('tempHolidays')
					.where('hospitalWorkerId', '==', hospitalWorkerId)
					.orderBy('date', 'asc');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				console.log('loadTempHolidaysHospitalOfHospitalWorker result', itemList);
				return itemList;
			},
			loadTempHolidaysPharmacy: async function (pharmacyId, limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('tempHolidays')
					.where('pharmacyId', '==', pharmacyId)
					.orderBy('date', 'asc');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadTempHoliday: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('tempHolidays')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addTempHoliday: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('tempHolidays')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editTempHoliday: async function (id, model) {
				await firebase.firestore()
					.collection('tempHolidays')
					.doc(id)
					.update(model);
			},
			deleteTempHoliday: async function (id) {
				await firebase.firestore()
					.collection('tempHolidays')
					.doc(id)
					.delete();
			},
			setTempHoliday: async function (id, model) {
				await firebase.firestore()
					.collection('tempHolidays')
					.doc(id)
					.set(model);
			},
			onRequestCertificates: function (onSnapshot) {
				return firebase.firestore()
					.collection('requestCertificates')
					.onSnapshot(onSnapshot);
			},
			loadRequestCertificatesByHospitalWorkId: async function (hospitalWorkId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('requestCertificates')
					.where('hospitalWorkId', '==', hospitalWorkId)
					.where('state', '==', 'accepted')
					.orderBy('createAt', 'desc');
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadRequestCertificate: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('requestCertificates')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addRequestCertificate: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('requestCertificates')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editRequestCertificate: async function (id, model) {
				await firebase.firestore()
					.collection('requestCertificates')
					.doc(id)
					.update(model);
			},
			deleteRequestCertificate: async function (id) {
				await firebase.firestore()
					.collection('requestCertificates')
					.doc(id)
					.delete();
			},
			setRequestCertificate: async function (id, model) {
				await firebase.firestore()
					.collection('requestCertificates')
					.doc(id)
					.set(model);
			},
			loadRequestCertificates: async function () {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('requestCertificates')
					.where('state', 'in', ['accepted']);
				await ref
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadRequestCertificatesHospitalSettle: async function (createAtStart, createAtEnd, hospitalNameFilter) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('requestCertificates')
					.where('state', '==', 'accepted');
				if ((createAtStart !== null) && (createAtEnd !== null)) {
					ref = ref.where('createAt', '>=', createAtStart)
						.where('createAt', '<=', createAtEnd);
				}
				if (hospitalNameFilter.length > 0) {
					ref = ref.where('hospitalName', '==', hospitalNameFilter);
				}
				await ref.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			onHospitalWorkUpdateLogs: function (hospitalWorkId, onSnapshot) {
				return firebase.firestore()
					.collection('hospitalWorks')
					.doc(hospitalWorkId)
					.collection('hospitalWorkUpdateLogs')
					.onSnapshot(onSnapshot);
			},
			loadHospitalWorkUpdateLogs: async function (hospitalWorkId) {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalWorks')
					.doc(hospitalWorkId)
					.collection('hospitalWorkUpdateLogs')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadHospitalWorkUpdateLog: async function (hospitalWorkId, id) {
				let item = null;
				await firebase.firestore()
					.collection('hospitalWorks')
					.doc(hospitalWorkId)
					.collection('hospitalWorkUpdateLogs')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addHospitalWorkUpdateLog: async function (hospitalWorkId, model) {
				let newDocRef = firebase.firestore()
					.collection('hospitalWorks')
					.doc(hospitalWorkId)
					.collection('hospitalWorkUpdateLogs')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editHospitalWorkUpdateLog: async function (hospitalWorkId, id, model) {
				await firebase.firestore()
					.collection('hospitalWorks')
					.doc(hospitalWorkId)
					.collection('hospitalWorkUpdateLogs')
					.doc(id)
					.update(model);
			},
			deleteHospitalWorkUpdateLog: async function (hospitalWorkId, id) {
				await firebase.firestore()
					.collection('hospitalWorks')
					.doc(hospitalWorkId)
					.collection('hospitalWorkUpdateLogs')
					.doc(id)
					.delete();
			},
			setHospitalWorkUpdateLog: async function (hospitalWorkId, id, model) {
				await firebase.firestore()
					.collection('hospitalWorks')
					.doc(hospitalWorkId)
					.collection('hospitalWorkUpdateLogs')
					.doc(id)
					.set(model);
			},
			onReservedPushNotifications: function (onSnapshot) {
				return firebase.firestore()
					.collection('reservedPushNotifications')
					.onSnapshot(onSnapshot);
			},
			loadReservedPushNotification: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('reservedPushNotifications')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addReservedPushNotification: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('reservedPushNotifications')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editReservedPushNotification: async function (id, model) {
				await firebase.firestore()
					.collection('reservedPushNotifications')
					.doc(id)
					.update(model);
			},
			deleteReservedPushNotification: async function (id) {
				await firebase.firestore()
					.collection('reservedPushNotifications')
					.doc(id)
					.delete();
			},
			setReservedPushNotification: async function (id, model) {
				await firebase.firestore()
					.collection('reservedPushNotifications')
					.doc(id)
					.set(model);
			},
			acceptReservation: async function (id) {
				let result = false;
				await firebase.firestore().runTransaction(async (transaction) => {
					var ref = firebase.firestore()
						.collection('hospitalWorks')
						.doc(id);
					await transaction.get(ref).then((documentSnapshot) => {
						if (documentSnapshot.exists) {
							let hospitalWork = documentSnapshot.data();
							if (hospitalWork.clinicState === 'ClinicState.waiting') {
								transaction.update(ref, {
									clinicState: 'ClinicState.accepted'
								});
								result = true;
							}
						}
					});
				});
				return result;
			},
			startReservation: async function (id, model) {
				let result = false;
				await firebase.firestore().runTransaction(async (transaction) => {
					var ref = firebase.firestore()
						.collection('hospitalWorks')
						.doc(id);
					await transaction.get(ref).then((documentSnapshot) => {
						if (documentSnapshot.exists) {
							let hospitalWork = documentSnapshot.data();
							if (hospitalWork.clinicState === 'ClinicState.accepted') {
								transaction.update(ref, model);
								result = true;
							}
						}
					});
				});
				return result;
			},
			editMembershipUsageClinicSeconds: async function (hospital, clinicType, clinicSeconds) {
				let thisMonth = new Date();
				thisMonth.setDate(1);
				thisMonth.setHours(0, 0, 0, 0);
				let nextMonth = new Date(thisMonth);
				nextMonth.setMonth(nextMonth.getMonth() + 1);
				let thisMonthTimestamp = firebase.firestore.Timestamp.fromDate(thisMonth);
				let nextMonthTimestamp = firebase.firestore.Timestamp.fromDate(nextMonth);
				await firebase.firestore()
					.collection('membershipUsages')
					.where('hospitalId', '==', hospital.id)
					.where('month', '>=', thisMonthTimestamp)
					.where('month', '<', nextMonthTimestamp)
					.get()
					.then(async (querySnapshot) => {
						if (querySnapshot.docs.length > 0) {
							let ref = firebase.firestore()
								.collection('membershipUsages').doc(querySnapshot.docs[0].id);
							await firebase.firestore().runTransaction(async (transaction) => {
								await transaction.get(ref).then((documentSnapshot) => {
									if (documentSnapshot.exists) {
										let membershipUsage = documentSnapshot.data();
										if (clinicType === 'ClinicType.normal') {
											let newSeconds = membershipUsage.normalClinicSeconds + clinicSeconds;
											if (hospital.subscriptionPlan.clinicTimeUnlimited === false) {
												if (newSeconds >= hospital.subscriptionPlan.clinicTimeLimit) {
													let hospitalRef = firebase.firestore()
														.collection('hospitals')
														.doc(hospital.id);
													transaction.update(hospitalRef, { disableUntil: nextMonthTimestamp });
												}
											}
											transaction.update(ref, {
												normalClinicSeconds: newSeconds,
												normalClinicCount: membershipUsage.normalClinicCount + 1,
											});
										}
										else if (clinicType === 'ClinicType.video') {
											let newSeconds = membershipUsage.videoClinicSeconds + clinicSeconds;
											if (hospital.subscriptionPlan.clinicTimeUnlimited === false) {
												if (newSeconds >= hospital.subscriptionPlan.clinicTimeLimit) {
													let hospitalRef = firebase.firestore()
														.collection('hospitals')
														.doc(hospital.id);
													transaction.update(hospitalRef, { disableUntil: nextMonthTimestamp });
												}
											}
											transaction.update(ref, {
												videoClinicSeconds: membershipUsage.videoClinicSeconds + clinicSeconds,
												videoClinicCount: membershipUsage.videoClinicCount + 1,
											});
										}
										else if (clinicType === 'ClinicType.audio') {
											let newSeconds = membershipUsage.audioClinicSeconds + clinicSeconds;
											if (hospital.subscriptionPlan.clinicTimeUnlimited === false) {
												if (newSeconds >= hospital.subscriptionPlan.clinicTimeLimit) {
													let hospitalRef = firebase.firestore()
														.collection('hospitals')
														.doc(hospital.id);
													transaction.update(hospitalRef, { disableUntil: nextMonthTimestamp });
												}
											}
											transaction.update(ref, {
												audioClinicSeconds: membershipUsage.audioClinicSeconds + clinicSeconds,
												audioClinicCount: membershipUsage.audioClinicCount + 1,
											});
										}
									}
								});
							});
						}
						else {
							let addModel = {};
							addModel.hospitalId = hospital.id;
							addModel.month = thisMonth;
							addModel.pushCount = 0;
							addModel.normalClinicCount = 0;
							addModel.normalClinicSeconds = 0;
							addModel.audioClinicCount = 0;
							addModel.audioClinicSeconds = 0;
							addModel.videoClinicCount = 0;
							addModel.videoClinicSeconds = 0;

							if (clinicType === 'ClinicType.normal') {
								addModel.normalClinicCount = 1;
								addModel.normalClinicSeconds = clinicSeconds;
							}
							else if (clinicType === 'ClinicType.video') {
								addModel.videoClinicCount = 1;
								addModel.videoClinicSeconds = clinicSeconds;
							}
							else if (clinicType === 'ClinicType.audio') {
								addModel.audioClinicCount = 1;
								addModel.audioClinicSeconds = clinicSeconds;
							}

							await firebase.firestore()
								.collection('membershipUsages')
								.add(addModel);

							if (hospital.subscriptionPlan.clinicTimeUnlimited === false) {
								if (clinicSeconds >= hospital.subscriptionPlan.clinicTimeLimit) {
									await firebase.firestore()
										.collection('hospitals')
										.doc(hospital.id)
										.update({ disableUntil: nextMonthTimestamp });
								}
							}
						}
					});
			},
			onPayments: function (onSnapshot) {
				return firebase.firestore()
					.collection('payments')
					.onSnapshot(onSnapshot);
			},
			loadPayments: async function (hospitalId, hospitalWorkId) {
				let itemList = [];
				await firebase.firestore()
					.collection('payments')
					.where('hospitalId', '==', hospitalId)
					.where('hospitalWorkId', '==', hospitalWorkId)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPaymentsByRequestCertificate: async function (requestCertificateId) {
				let itemList = [];
				await firebase.firestore()
					.collection('payments')
					.where('certificateRequestId', '==', requestCertificateId)
					.orderBy('createAt', 'desc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPayment: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('payments')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPayment: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('payments')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
				return addModel.id;
			},
			editPayment: async function (id, model) {
				await firebase.firestore()
					.collection('payments')
					.doc(id)
					.update(model);
			},
			deletePayment: async function (id) {
				await firebase.firestore()
					.collection('payments')
					.doc(id)
					.delete();
			},
			setPayment: async function (id, model) {
				await firebase.firestore()
					.collection('payments')
					.doc(id)
					.set(model);
			},
			loadHospitalWorkers: async function () {
				let itemList = [];
				await firebase.firestore()
					.collection('hospitalWorkers')
					.orderBy('createAt', 'asc')
					.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			onDocuments: function (onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
				let ref = firebase.firestore()
					.collection('documents')
					.orderBy((orderBy !== null) ? orderBy : 'createAt', desc ? 'desc' : 'asc');
				if (startAfter !== null) {
					ref = ref.startAfter(startAfter);
				}
				if (startAt !== null) {
					ref = ref.startAt(startAt);
				}
				if (endBefore !== null) {
					ref = ref.endBefore(endBefore);
				}
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				return ref.onSnapshot(onSnapshot);
			},
			loadDocuments: async function (hospitalWorkId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('documents')
					.where('hospitalWorkId', '==', hospitalWorkId);
				await ref.orderBy('createAt', 'asc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadDocument: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('documents')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addDocument: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('documents')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
				return addModel.id;
			},
			editDocument: async function (id, model) {
				await firebase.firestore()
					.collection('documents')
					.doc(id)
					.update(model);
			},
			deleteDocument: async function (id) {
				await firebase.firestore()
					.collection('documents')
					.doc(id)
					.delete();
			},
			setDocument: async function (id, model) {
				await firebase.firestore()
					.collection('documents')
					.doc(id)
					.set(model);
			},
			onManuals: function (onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
				let ref = firebase.firestore()
					.collection('manuals')
					.orderBy((orderBy !== null) ? orderBy : 'createAt', desc ? 'desc' : 'asc');
				if (startAfter !== null) {
					ref = ref.startAfter(startAfter);
				}
				if (startAt !== null) {
					ref = ref.startAt(startAt);
				}
				if (endBefore !== null) {
					ref = ref.endBefore(endBefore);
				}
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				return ref.onSnapshot(onSnapshot);
			},
			loadManuals: async function (type, limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('manuals')
					.where('type', '==', type);
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadManual: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('manuals')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addManual: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('manuals')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editManual: async function (id, model) {
				await firebase.firestore()
					.collection('manuals')
					.doc(id)
					.update(model);
			},
			deleteManual: async function (id) {
				await firebase.firestore()
					.collection('manuals')
					.doc(id)
					.delete();
			},
			setManual: async function (id, model) {
				await firebase.firestore()
					.collection('manuals')
					.doc(id)
					.set(model);
			},
			onSignUpCodeUsers: function (onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
				let ref = firebase.firestore()
					.collection('signUpCodeUsers')
					.orderBy((orderBy !== null) ? orderBy : 'createAt', desc ? 'desc' : 'asc');
				if (startAfter !== null) {
					ref = ref.startAfter(startAfter);
				}
				if (startAt !== null) {
					ref = ref.startAt(startAt);
				}
				if (endBefore !== null) {
					ref = ref.endBefore(endBefore);
				}
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				return ref.onSnapshot(onSnapshot);
			},
			loadSignUpCodeUsers: async function (limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('signUpCodeUsers');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadSignUpCodeUser: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('signUpCodeUsers')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addSignUpCodeUser: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('signUpCodeUsers')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editSignUpCodeUser: async function (id, model) {
				await firebase.firestore()
					.collection('signUpCodeUsers')
					.doc(id)
					.update(model);
			},
			deleteSignUpCodeUser: async function (id) {
				await firebase.firestore()
					.collection('signUpCodeUsers')
					.doc(id)
					.delete();
			},
			setSignUpCodeUser: async function (id, model) {
				await firebase.firestore()
					.collection('signUpCodeUsers')
					.doc(id)
					.set(model);
			},
			onSignUpCodeChannels: function (onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
				let ref = firebase.firestore()
					.collection('signUpCodeChannels')
					.orderBy((orderBy !== null) ? orderBy : 'createAt', desc ? 'desc' : 'asc');
				if (startAfter !== null) {
					ref = ref.startAfter(startAfter);
				}
				if (startAt !== null) {
					ref = ref.startAt(startAt);
				}
				if (endBefore !== null) {
					ref = ref.endBefore(endBefore);
				}
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				return ref.onSnapshot(onSnapshot);
			},
			// loadAllSignUpCodeChannels: async function () {
			// 	let itemList = [];
			// 	let ref = firebase.firestore()
			// 		.collection('signUpCodeChannels');
			// 	await ref.get()
			// 		.then(async (querySnapshot) => {
			// 			for (let i = 0; i < querySnapshot.docs.length; i++) {
			// 				let itemInfo = querySnapshot.docs[i].data();
			// 				itemInfo.id = querySnapshot.docs[i].id;
			// 				itemList.push(itemInfo);
			// 			}
			// 		});
			// 	return itemList;
			// },
			loadAllSignUpCodeChannels: async function () {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('signUpCodeChannels');
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadSignUpCodeChannels: async function (signUpCodeUserId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('signUpCodeChannels')
					.where('signUpCodeUserId', '==', signUpCodeUserId);
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadSignUpCodeChannel: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('signUpCodeChannels')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			loadSignUpCodeChannelsBySignUpCode: async function (signUpCode) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('signUpCodeChannels')
					.where('signUpCode', '==', signUpCode);
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			addSignUpCodeChannel: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('signUpCodeChannels')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editSignUpCodeChannel: async function (id, model) {
				await firebase.firestore()
					.collection('signUpCodeChannels')
					.doc(id)
					.update(model);
			},
			deleteSignUpCodeChannel: async function (id) {
				await firebase.firestore()
					.collection('signUpCodeChannels')
					.doc(id)
					.delete();
			},
			setSignUpCodeChannel: async function (id, model) {
				await firebase.firestore()
					.collection('signUpCodeChannels')
					.doc(id)
					.set(model);
			},
			onNonBenefitOptions: function (onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
				let ref = firebase.firestore()
					.collection('nonBenefitOptions')
					.orderBy((orderBy !== null) ? orderBy : 'createAt', desc ? 'desc' : 'asc');
				if (startAfter !== null) {
					ref = ref.startAfter(startAfter);
				}
				if (startAt !== null) {
					ref = ref.startAt(startAt);
				}
				if (endBefore !== null) {
					ref = ref.endBefore(endBefore);
				}
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				return ref.onSnapshot(onSnapshot);
			},
			loadNonBenefitOptions: async function (hospitalId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('nonBenefitOptions')
					.where('hospitalId', '==', hospitalId)
					.orderBy('name', 'asc');
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadNonBenefitOption: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('nonBenefitOptions')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addNonBenefitOption: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('nonBenefitOptions')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editNonBenefitOption: async function (id, model) {
				await firebase.firestore()
					.collection('nonBenefitOptions')
					.doc(id)
					.update(model);
			},
			deleteNonBenefitOption: async function (id) {
				await firebase.firestore()
					.collection('nonBenefitOptions')
					.doc(id)
					.delete();
			},
			setNonBenefitOption: async function (id, model) {
				await firebase.firestore()
					.collection('nonBenefitOptions')
					.doc(id)
					.set(model);
			},
			onReservationCloses: function (onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
				let ref = firebase.firestore()
					.collection('reservationCloses')
					.orderBy((orderBy !== null) ? orderBy : 'createAt', desc ? 'desc' : 'asc');
				if (startAfter !== null) {
					ref = ref.startAfter(startAfter);
				}
				if (startAt !== null) {
					ref = ref.startAt(startAt);
				}
				if (endBefore !== null) {
					ref = ref.endBefore(endBefore);
				}
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				return ref.onSnapshot(onSnapshot);
			},
			loadAllReservationCloses: async function (hospitalId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('reservationCloses')
					.where('hospitalId', '==', hospitalId);
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadReservationCloses: async function (hospitalId, from, to) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('reservationCloses')
					.where('hospitalId', '==', hospitalId)
					.where('time', '>=', from)
					.where('time', '<=', to);
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadReservationClose: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('reservationCloses')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addReservationClose: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('reservationCloses')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editReservationClose: async function (id, model) {
				await firebase.firestore()
					.collection('reservationCloses')
					.doc(id)
					.update(model);
			},
			deleteReservationClose: async function (id) {
				await firebase.firestore()
					.collection('reservationCloses')
					.doc(id)
					.delete();
			},
			setReservationClose: async function (id, model) {
				await firebase.firestore()
					.collection('reservationCloses')
					.doc(id)
					.set(model);
			},
			updateReservationClose: async function (hospitalId, doctorId, close, time) {
				console.log('updateReservationClose', hospitalId, doctorId, close, time.toDate());
				await firebase.firestore()
					.collection('reservationCloses')
					.where('hospitalId', '==', hospitalId)
					.where('doctorId', '==', doctorId)
					.get()
					.then(async (snapshot) => {
						if (snapshot.docs.length > 0) {
							if (close === false) {
								let ref = snapshot.docs[0].ref;
								await firebase.firestore()
									.collection('reservationCloses')
									.doc(ref.id)
									.delete();
							}
						}
						else {
							if (close) {
								let ref = firebase.firestore()
									.collection('reservationCloses')
									.doc();
								await firebase.firestore()
									.collection('reservationCloses')
									.doc(ref.id)
									.set({
										id: ref.id,
										createAt: firebase.firestore.Timestamp.now(),
										hospitalId: hospitalId,
										doctorId: doctorId,
										time: time
									});
							}
						}
					});
			},
			onSellerUsers: function (onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
				let ref = firebase.firestore()
					.collection('sellerUsers')
					.orderBy((orderBy !== null) ? orderBy : 'createAt', desc ? 'desc' : 'asc');
				if (startAfter !== null) {
					ref = ref.startAfter(startAfter);
				}
				if (startAt !== null) {
					ref = ref.startAt(startAt);
				}
				if (endBefore !== null) {
					ref = ref.endBefore(endBefore);
				}
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				return ref.onSnapshot(onSnapshot);
			},
			loadSellerUsers: async function (limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('sellerUsers');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadSellerUser: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('sellerUsers')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addSellerUser: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('sellerUsers')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editSellerUser: async function (id, model) {
				console.log('editSellerUser db', model);
				await firebase.firestore()
					.collection('sellerUsers')
					.doc(id)
					.update(model);
			},
			deleteSellerUser: async function (id) {
				await firebase.firestore()
					.collection('sellerUsers')
					.doc(id)
					.delete();
			},
			setSellerUser: async function (id, model) {
				await firebase.firestore()
					.collection('sellerUsers')
					.doc(id)
					.set(model);
			},
			onQuotations: function (onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
				let ref = firebase.firestore()
					.collection('quotations')
					.orderBy((orderBy !== null) ? orderBy : 'createAt', desc ? 'desc' : 'asc');
				if (startAfter !== null) {
					ref = ref.startAfter(startAfter);
				}
				if (startAt !== null) {
					ref = ref.startAt(startAt);
				}
				if (endBefore !== null) {
					ref = ref.endBefore(endBefore);
				}
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				return ref.onSnapshot(onSnapshot);
			},
			loadQuotations: async function (limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('quotations');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadQuotationsByQuotationRequestId: async function (quotationRequestId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('quotations')
					.where('quotationRequestId', '==', quotationRequestId);
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadQuotationsBySellerId: async function (sellerId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('quotations')
					.where('sellerId', '==', sellerId);
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadQuotation: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('quotations')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addQuotation: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('quotations')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
				return addModel.id;
			},
			editQuotation: async function (id, model) {
				await firebase.firestore()
					.collection('quotations')
					.doc(id)
					.update(model);
			},
			deleteQuotation: async function (id) {
				await firebase.firestore()
					.collection('quotations')
					.doc(id)
					.delete();
			},
			setQuotation: async function (id, model) {
				await firebase.firestore()
					.collection('quotations')
					.doc(id)
					.set(model);
			},
			onOrders: function (onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
				let ref = firebase.firestore()
					.collection('orders')
					.orderBy((orderBy !== null) ? orderBy : 'createAt', desc ? 'desc' : 'asc');
				if (startAfter !== null) {
					ref = ref.startAfter(startAfter);
				}
				if (startAt !== null) {
					ref = ref.startAt(startAt);
				}
				if (endBefore !== null) {
					ref = ref.endBefore(endBefore);
				}
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				return ref.onSnapshot(onSnapshot);
			},
			loadOrders: async function (limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('orders');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadOrdersBySellerId: async function (sellerId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('orders')
					.where('sellerId', '==', sellerId)
					.where('orderState', '!=', 'OrderState.cancel');
				await ref.get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadOrder: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('orders')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addOrder: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('orders')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editOrder: async function (id, model) {
				await firebase.firestore()
					.collection('orders')
					.doc(id)
					.update(model);
			},
			deleteOrder: async function (id) {
				await firebase.firestore()
					.collection('orders')
					.doc(id)
					.delete();
			},
			setOrder: async function (id, model) {
				await firebase.firestore()
					.collection('orders')
					.doc(id)
					.set(model);
			},
			onUserPointUsages: function (userId, onSnapshot) {
				return firebase.firestore()
					.collection('userPointUsages')
					.where('userId', '==', userId)
					.orderBy('createAt', 'desc').onSnapshot(onSnapshot);
			},
			loadUserPointUsages: async function (userId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('userPointUsages')
					.where('userId', '==', userId);
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadUserPointUsagesByHospitalWorkId: async function (hospitalWorkId) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('userPointUsages')
					.where('hospitalWorkId', '==', hospitalWorkId);
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadUserPointUsage: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('userPointUsages')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addUserPointUsage: async function (userPointId, model) {
				let newDocRef = firebase.firestore()
					.collection('userPointUsages')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;

				let userPointRef = firebase.firestore()
					.collection('userPoints')
					.doc(userPointId);
				await firebase.firestore().runTransaction(async (transaction) => {
					transaction.set(newDocRef, addModel);
					transaction.update(userPointRef, {
						amount: firebase.firestore.FieldValue.increment(model.amount),
					});
				});
			},
			editUserPointUsage: async function (id, model) {
				await firebase.firestore()
					.collection('userPointUsages')
					.doc(id)
					.update(model);
			},
			deleteUserPointUsage: async function (id) {
				await firebase.firestore()
					.collection('userPointUsages')
					.doc(id)
					.delete();
			},
			setUserPointUsage: async function (id, model) {
				await firebase.firestore()
					.collection('userPointUsages')
					.doc(id)
					.set(model);
			},
			loadUserPoints: async function (limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('userPoints');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadUserPoint: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('userPoints')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			loadUserPointByHospitalIdUserId: async function (hospitalId, userId) {
				let item = null;
				await firebase.firestore()
					.collection('userPoints')
					.where('hospitalId', '==', hospitalId)
					.where('userId', '==', userId)
					.get()
					.then(async (querySnapshot) => {
						if (querySnapshot.docs.length) {
							item = querySnapshot.docs[0].data();
						}
					});
				return item;
			},
			addUserPoint: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('userPoints')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editUserPoint: async function (id, model) {
				await firebase.firestore()
					.collection('userPoints')
					.doc(id)
					.update(model);
			},
			deleteUserPoint: async function (id) {
				await firebase.firestore()
					.collection('userPoints')
					.doc(id)
					.delete();
			},
			setUserPoint: async function (id, model) {
				await firebase.firestore()
					.collection('userPoints')
					.doc(id)
					.set(model);
			},
			onUserPoints: function (userId, hospitalId, onSnapshot) {
				let ref = firebase.firestore()
					.collection('userPoints')
					.where('userId', '==', userId)
					.where('hospitalId', '==', hospitalId)
					.orderBy('createAt', 'desc');
				return ref.onSnapshot(onSnapshot);
			},
			onPartnerPointUsages: function (onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
				let ref = firebase.firestore()
					.collection('partnerPointUsages')
					.orderBy((orderBy !== null) ? orderBy : 'createAt', desc ? 'desc' : 'asc');
				if (startAfter !== null) {
					ref = ref.startAfter(startAfter);
				}
				if (startAt !== null) {
					ref = ref.startAt(startAt);
				}
				if (endBefore !== null) {
					ref = ref.endBefore(endBefore);
				}
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				return ref.onSnapshot(onSnapshot);
			},
			loadPartnerPointUsages: async function (limit) {
				let itemList = [];
				let ref = firebase.firestore()
					.collection('partnerPointUsages');
				if (limit !== null) {
					ref = ref.limit(limit);
				}
				await ref.orderBy('createAt', 'desc').get()
					.then(async (querySnapshot) => {
						for (let i = 0; i < querySnapshot.docs.length; i++) {
							let itemInfo = querySnapshot.docs[i].data();
							itemInfo.id = querySnapshot.docs[i].id;
							itemList.push(itemInfo);
						}
					});
				return itemList;
			},
			loadPartnerPointUsage: async function (id) {
				let item = null;
				await firebase.firestore()
					.collection('partnerPointUsages')
					.doc(id)
					.get()
					.then(async (documentSnapshot) => {
						if (documentSnapshot.exists) {
							item = documentSnapshot.data();
						}
					});
				return item;
			},
			addPartnerPointUsage: async function (model) {
				let newDocRef = firebase.firestore()
					.collection('partnerPointUsages')
					.doc();
				let addModel = model;
				addModel.id = newDocRef.id;
				await newDocRef.set(addModel);
			},
			editPartnerPointUsage: async function (id, model) {
				await firebase.firestore()
					.collection('partnerPointUsages')
					.doc(id)
					.update(model);
			},
			deletePartnerPointUsage: async function (id) {
				await firebase.firestore()
					.collection('partnerPointUsages')
					.doc(id)
					.delete();
			},
			setPartnerPointUsage: async function (id, model) {
				await firebase.firestore()
					.collection('partnerPointUsages')
					.doc(id)
					.set(model);
			},
		}
	}
};

Vue.use(database);
